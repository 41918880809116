import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`Single Leg BLB’s 4-4-4-4-4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`15 Back Squat Buy In (185/125)`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`20-OH Walking Lunges (45/25)`}</p>
    <p>{`10-Burpees onto 45/25 plate`}</p>
    <p>{`15 Back Squat Cash Out (185/125)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Memorial Day Murph will take place next Monday at Louisville East
at 10:00am!  All are welcome.  All other classes and open gym will be
cancelled for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      